import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { BnplAuthPage } from './bnplauth.page';



const routes: Routes = [
  {
    path: '',
    component: BnplAuthPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BnplPageRoutingModule {
}
