import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {Constant} from "../shared/model/Constant";

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem(Constant.token);
    if (token && !req.url.includes('geolocation-db')) {
      req = req.clone({
        setHeaders: {
          'Authorization': token
        }
      });
    }
    
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        localStorage.setItem(Constant.authorization, 'true');
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          localStorage.setItem(Constant.authorization, 'false');
          window.location.href = '/login';
          localStorage.clear();
        }
        return throwError(error);
      }));
  }
}
