import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CinfoPage } from './cinfo.page';

const routes: Routes = [
  {
    path: '',
    component: CinfoPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CinfoPageRoutingModule {}
