import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {map} from "rxjs/operators";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  urlProductCategoryType = environment.api.productCategoryType;
  urlCommissionCategory = environment.api.commissionCategory;
  urlCommissionType = environment.api.commissionType;
  urlProductsTypes = environment.api.productsTypes;
  urlTypesTaxes = environment.api.typeTaxes;
  urlStatusGlobalId = environment.api.statusGlobalId;
  urlProducts = environment.api.products;
  urlProduct = environment.api.product;
  urlProductByProvider = environment.api.productsByProvider;

  constructor(private http: HttpClient) {
  }

  getProductCategoryId() {
    return this.http.get(this.urlProductCategoryType)
      .pipe(map(results => results));
  }


  getCommissionCategory() {
    return this.http.get(this.urlCommissionCategory)
      .pipe(map(results => results));
  }

  getCommissionType() {
    return this.http.get(this.urlCommissionType)
      .pipe(map(results => results));
  }

  getProductsTypes() {
    return this.http.get(this.urlProductsTypes)
      .pipe(map(results => results));
  }

  getProductsByProviderId(providerId: number) {
    const url = `${this.urlProductByProvider}/${providerId}`;
    return this.http.get(url)
      .pipe(map(results => results));
  }

  getTypeTaxes() {
    return this.http.get(this.urlTypesTaxes)
      .pipe(map(results => results));
  }

  createProducts(data) {
    return this.http.post(this.urlProducts, data)
      .pipe(map(results => results));
  }

  getGlobalStatus() {
    return this.http.get(this.urlStatusGlobalId)
      .pipe(map(results => results));
  }

  getProduct() {
    return this.http.get(this.urlProduct)
      .pipe(map(results => results));
  }

  getActiveTaxesByProduct(productId: number) {
    const url = this.urlProduct.concat("/taxes/state/", productId.toString());
    return this.http.get(url)
    .pipe(map(results => results));
  }
  
  getTaxesByProduct(productId: number) {
    const url = this.urlProduct.concat("/taxes/", productId.toString());
    return this.http.get(url)
    .pipe(map(results => results));
  }

  getAllTaxes() {
    const url = this.urlProduct.concat("/taxes");
    return this.http.get(url)
    .pipe(map(results => results));
  }

  registerTaxes(data) {
    const url = this.urlProduct.concat("/taxes");
    return this.http.post(url, data, { responseType: 'text'})
    .pipe(map(results => results));
  }

}
