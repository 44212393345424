import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ChooseplanPageRoutingModule } from './chooseplan-routing.module';

import { ChooseplanPage } from './chooseplan.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ChooseplanPageRoutingModule
  ],
  declarations: [ChooseplanPage]
})
export class ChooseplanPageModule {}
