import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { BnplPageRoutingModule } from './bnplauth-routing.module';
import {Ng2SearchPipeModule} from "ng2-search-filter";
import {JwPaginationModule} from "jw-angular-pagination";
import { BnplAuthPage } from './bnplauth.page';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        BnplPageRoutingModule,
        Ng2SearchPipeModule,
        JwPaginationModule
    ],
    exports: [
        
    ],
    declarations: [BnplAuthPage]
})
export class BnplPageModule {}
