import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then(m => m.FolderPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./main/main.module').then(m => m.MainPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then(m => m.SignupPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'nice',
    loadChildren: () => import('./nice/nice.module').then(m => m.NicePageModule)
  },
  {
    path: 'client/:type',
    loadChildren: () => import('./client/client.module').then(m => m.ClientPageModule)
  },
  {
    path: 'invoice/:type',
    loadChildren: () => import('./invoice/invoice.module').then(m => m.InvoicePageModule)
  },
  {
    path: 'product',
    loadChildren: () => import('./product/product-management/product-management.module').then(m => m.ProductManagementPageModule)
  },
  {
    path: 'product-old',
    loadChildren: () => import('./product-old/product.module').then(m => m.ProductPageModule)
  },
  {
    path: 'product-assign',
    loadChildren: () => import('./product-assign/product-assign.module').then( m => m.ProductAssignPageModule)
  },
  {
    path: 'main',
    loadChildren: () => import('./main/main.module').then(m => m.MainPageModule)
  },
  {
    path: 'forgot',
    loadChildren: () => import('./forgot/forgot.module').then(m => m.ForgotPageModule)
  },
  {
    path: 'preview/:type',
    loadChildren: () => import('./preview/preview.module').then(m => m.PreviewPageModule)
  },
  {
    path: 'list',
    loadChildren: () => import('./list/list.module').then(m => m.ListPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then(m => m.AccountPageModule)
  },
  {
    path: 'setting',
    loadChildren: () => import('./setting/setting.module').then(m => m.SettingPageModule)
  },
  {
    path: 'abount',
    loadChildren: () => import('./abount/abount.module').then(m => m.AbountPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then(m => m.AboutPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then(m => m.ContactPageModule)
  },
  {
    path: 'clist',
    loadChildren: () => import('./clist/clist.module').then(m => m.ClistPageModule)
  },
  {
    path: 'gsetting',
    loadChildren: () => import('./gsetting/gsetting.module').then(m => m.GsettingPageModule)
  },
  {
    path: 'legal',
    loadChildren: () => import('./legal/legal.module').then(m => m.LegalPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then(m => m.TermsPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomePageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./menu/menu.module').then(m => m.MenuPageModule)
  },
  {
    path: 'plan',
    loadChildren: () => import('./plan/plan.module').then(m => m.PlanPageModule)
  },
  {
    path: 'chooseplan',
    loadChildren: () => import('./chooseplan/chooseplan.module').then(m => m.ChooseplanPageModule)
  },
  {
    path: 'logo',
    loadChildren: () => import('./logo/logo.module').then(m => m.LogoPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then(m => m.PaymentPageModule)
  },
  {
    path: 'product-add/:id',
    loadChildren: () => import('./product-old/product-add/product-add.module').then(m => m.ProductAddPageModule)
  },
  {
    path: 'client-list',
    loadChildren: () => import('./client-list/client-list.module').then(m => m.ClientListPageModule)
  },
  {
    path: 'client-add/:id',
    loadChildren: () => import('./client-add/client-add.module').then(m => m.ClientAddPageModule)
  },
  {
    path: 'cinfo',
    loadChildren: () => import('./cinfo/cinfo.module').then(m => m.CinfoPageModule)
  },
  {
    path: 'idetail',
    loadChildren: () => import('./idetail/idetail.module').then(m => m.IdetailPageModule)
  },
  {
    path: 'report',
    loadChildren: () => import('./report/report.module').then(m => m.ReportPageModule)
  },
  {
    path: 'income',
    loadChildren: () => import('./income/income.module').then(m => m.IncomePageModule)
  },
  {
    path: 'incomeadd',
    loadChildren: () => import('./incomeadd/incomeadd.module').then(m => m.IncomeaddPageModule)
  },
  {
    path: 'expense',
    loadChildren: () => import('./expense/expense.module').then(m => m.ExpensePageModule)
  },
  {
    path: 'expenseadd',
    loadChildren: () => import('./expenseadd/expenseadd.module').then(m => m.ExpenseaddPageModule)
  },
  {
    path: 'task',
    loadChildren: () => import('./task/task.module').then(m => m.TaskPageModule)
  },
  {
    path: 'taskadd',
    loadChildren: () => import('./taskadd/taskadd.module').then(m => m.TaskaddPageModule)
  },
  {
    path: 'taskview',
    loadChildren: () => import('./taskview/taskview.module').then(m => m.TaskviewPageModule)
  },
  {
    path: 'profit',
    loadChildren: () => import('./profit/profit.module').then(m => m.ProfitPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then(m => m.FaqPageModule)
  },
  {
    path: 'language',
    loadChildren: () => import('./language/language.module').then(m => m.LanguagePageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'advertising',
    loadChildren: () => import('./advertising/advertising.module').then(m => m.AdvertisingPageModule)
  },
  {
    path: 'advertising-add',
    loadChildren: () => import('./advertising/advertising-add/advertising-add.module').then(m => m.AdvertisingAddPageModule)
  },
  {
    path: 'modules',
    loadChildren: () => import('./modules/modules.module').then(m => m.ModulesPageModule)
  },
  {
    path: 'recharge',
    loadChildren: () => import('./recharge/recharge.module').then(m => m.RechargePageModule)
  },
  {
    path: 'packages',
    loadChildren: () => import('./packages/packages.module').then(m => m.PackagesPageModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersPageModule)
  },
  {
    path: 'createForms',
    loadChildren: () => import('./create-forms/create-forms.module').then(m => m.CreateFormsPageModule)
  },
  {
    path: 'supplier',
    loadChildren: () => import('./supplier/supplier.module').then(m => m.SupplierPageModule)
  },
  {
    path: 'purchaseOrder',
    loadChildren: () => import('./purchase-order/create-order/create-order.module').then(m => m.PurchaseOrderPageModule)
  },
  {
    path: 'order',
    loadChildren: () => import('./order/client/order.module').then(m => m.OrderPageModule),
    pathMatch: 'full',
  },
  {
    path: 'listOrders',
    loadChildren: () => import('./purchase-order/list-orders/list-orders.module').then(m => m.ListOrdersPageModule)
  },
  {
    path: 'shops',
    loadChildren: () => import('./shops/shops.module').then(m => m.ShopsPageModule)
  },
  {
    path: 'shopsSe',
    loadChildren: () => import('./shops-self-management/shops-self-management.module').then(m => m.ShopsPageSeModule)
  },
  {
    path: 'record',
    loadChildren: () => import('./record/record.module').then(m => m.RecordPageModule)
  },
  {
    path: 'select-product',
    loadChildren: () => import('./select-product/select-product.module').then(m => m.SelectProductPageModule)
  },
  {
    path: 'distributor',
    loadChildren: () => import('./provider/distributor-form/distributor-form.module').then(m => m.ProviderFormPageModule)
  },

  {
    path: 'authSession',
    loadChildren: () => import('./order/bnplauth/bnplauth.module').then(m => m.BnplPageModule)
  },
  {
    path: 'authSign',
    loadChildren: () => import('./order/bnplauth/bnplauth.module').then(m => m.BnplPageModule)
  },
  {
    path: 'companies',
    loadChildren: () => import('./companies/companies.module').then(m => m.CompaniesPageModule)
  },
  {
    path: 'company',
    loadChildren: () => import('./company/company.module').then(m => m.CompanyPageModule)
  },

  {
    path: 'detailOrder',
    loadChildren: () => import('./order/detail/detail.module').then(m => m.DetailPageModule),
    pathMatch: 'full',
  },
  {
    path: 'authBnpl',
    loadChildren: () => import('./order/bnplauth/bnplauth.module').then(m => m.BnplPageModule),
    pathMatch: 'full',
  },
  {
    path: 'nice',
    loadChildren: () => import('./nice/nice.module').then(m => m.NicePageModule)
  },
  {
    path: 'termsBnpl',
    loadChildren: () => import('./order/terms/terms.module').then(m => m.TermsPageBnplModule),
    pathMatch: 'full',
  },
  {
    path: 'supplier-assign',
    loadChildren: () => import('./supplier-assign/supplier-assign.module').then( m => m.SupplierAssignPageModule)
  },
  {
    path: 'supplier-to-company',
    loadChildren: () => import('./supplier-to-company/supplier-to-company.module').then( m => m.SupplierToCompanyPageModule)
  },
  {
    path: 'report-network',
    loadChildren: () => import('./report-network/report-network.module').then( m => m.ReportNetworkPageModule)
  },
  {
    path: 'product-to-company',
    loadChildren: () => import('./supplier-to-company/product-to-company/product-to-company.module').then( m => m.ProductToCompanyPageModule)
  },
  {
    path: 'pointofsale',
    loadChildren: () => import('./pointofsale/pointofsale.module').then( m => m.PointofsalePageModule)
  },
  {
    path: 'update-data-entities',
    loadChildren: () => import('./update-data-entities/update-data-entities.module').then( m => m.UpdateDataEntitiesPageModule)
  },
  {
    path: 'balance',
    loadChildren: () => import('./order/client-balance/balance.module').then(m => m.BalancePageModule),
    pathMatch: 'full',
  },


];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
