import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constant } from 'src/app/shared/model/Constant';
import { OrderResponse } from '../../shared/orderResponse.model';
import { DetailBnpl } from 'src/app/shared/detailBnpl.model';
import { QueryBalance } from 'src/app/shared/queryBalance.model';

@Component({
  selector: 'app-bnplauth',
  templateUrl: './bnplauth.page.html',
  styleUrls: ['./bnplauth.page.scss'],
})
export class BnplAuthPage implements OnInit {
  code: string;
  order: OrderResponse;
  detail: DetailBnpl;
  query: QueryBalance;
  constructor(
    private router: Router
  ) {

    var urlTree = this.router.parseUrl(this.router.url);
    this.code = urlTree.queryParams['code'];
    console.log("code " + this.code);

    this.order = JSON.parse(localStorage.getItem(Constant.bnpl));
    console.log("order ", this.order);

    this.detail = JSON.parse(localStorage.getItem(Constant.detailBnpl));
    console.log("detail ", this.detail);
    if (this.detail) {
      this.order = this.detail.order;
    }

    if (this.order) {
      this.order.codeBnpl = this.code;
      localStorage.setItem(Constant.bnpl, JSON.stringify(this.order));
      localStorage.setItem(Constant.detailBnpl, JSON.stringify(this.detail));
      this.router.navigate(['/order'],
        { queryParams: { id: this.order.idOrder } });

    }

    this.query = JSON.parse(localStorage.getItem(Constant.balance));
    console.log("query ", this.query);

    if (this.query) {
      this.query.code = this.code;
      localStorage.setItem(Constant.balance, JSON.stringify(this.query));
      this.router.navigate(['/balance']);
    }

    if(!this.order && !this.detail && !this.query){
      this.router.navigate(['/order'],
        { queryParams: { id: 0 } });
    }
    

  }

  ngOnInit() { }

}
