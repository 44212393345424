import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { TaskviewPageRoutingModule } from './taskview-routing.module';

import { TaskviewPage } from './taskview.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TaskviewPageRoutingModule
  ],
  declarations: [TaskviewPage]
})
export class TaskviewPageModule {}
