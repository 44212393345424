import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {Constant} from "../shared/model/Constant";

@Injectable({
  providedIn: 'root'
})
export class ServerService {

 url = 'https://lubett.com/backend/api/';

  constructor(private http: HttpClient) { }

  homepage()
  {
    return this.http.get(this.url+'homepage?user_id='+localStorage.getItem(Constant.userId))
             .pipe(map(results => results));
  }

  signup(data)
  {
    return this.http.post(this.url+'signup',data)
             .pipe(map(results => results));
  }

  login(data)
  {
    return this.http.post(this.url+'login',data)
             .pipe(map(results => results));
  }

  verify(id)
  {
    return this.http.get(this.url+'verify?id='+id)
             .pipe(map(results => results));
  }

  resend(phone)
  {
    return this.http.get(this.url+'resend?phone='+phone)
             .pipe(map(results => results));
  }

  forgot(data)
  {
    return this.http.post(this.url+'forgot',data)
             .pipe(map(results => results));
  }

  updatePassword(data)
  {
    return this.http.post(this.url+'updatePassword',data)
             .pipe(map(results => results));
  }

  getClient()
  {
    return this.http.get(this.url+'getClient?user_id='+localStorage.getItem(Constant.userId))
             .pipe(map(results => results));
  }

  save(data)
  {
    return this.http.post(this.url+'save',data)
             .pipe(map(results => results));
  }

  getList()
  {
    return this.http.get(this.url+'getList?user_id='+localStorage.getItem(Constant.userId))
             .pipe(map(results => results));
  }

  cancel(id)
  {
    return this.http.get(this.url+'cancel?id='+id+'&user_id='+localStorage.getItem(Constant.userId))
             .pipe(map(results => results));
  }

  account()
  {
    return this.http.get(this.url+'account?user_id='+4)
             .pipe(map(results => results));
  }

  saveAccount(data)
  {
    return this.http.post(this.url+'saveAccount?user_id='+localStorage.getItem(Constant.userId),data)
             .pipe(map(results => results));
  }

  clientList()
  {
    return this.http.get(this.url+'clientList?user_id='+localStorage.getItem(Constant.userId))
             .pipe(map(results => results));
  }

  contact(data)
  {
    return this.http.post(this.url+'contact',data)
             .pipe(map(results => results));
  }

  removeData()
  {
    return this.http.get(this.url+'removeData?id='+localStorage.getItem(Constant.userId))
             .pipe(map(results => results));
  }

  slider()
  {
    return this.http.get(this.url+'slider?lid='+localStorage.getItem(Constant.lid))
             .pipe(map(results => results));
  }

  buyplan(id,pid)
  {
    return this.http.get(this.url+'buyplan?lid='+localStorage.getItem(Constant.lid)+"&plan_id="+id+"&user_id="+localStorage.getItem(Constant.userId)+"&payment_method="+pid)
             .pipe(map(results => results));
  }

  myPlan()
  {
    return this.http.get(this.url+'myPlan?lid='+localStorage.getItem(Constant.lid)+"&user_id="+localStorage.getItem(Constant.userId))
             .pipe(map(results => results));
  }

  savePaymentgateway(data)
  {
    return this.http.post(this.url+'savePaymentgateway?lid='+localStorage.getItem(Constant.lid)+"&user_id="+localStorage.getItem(Constant.userId),data)
             .pipe(map(results => results));
  }

  getProduct()
  {
    return this.http.get(this.url+'getProduct?lid='+localStorage.getItem(Constant.lid)+"&user_id="+localStorage.getItem(Constant.userId))
             .pipe(map(results => results));
  }

  removeProduct(id)
  {
    return this.http.get(this.url+'removeProduct?lid='+localStorage.getItem(Constant.lid)+"&user_id="+localStorage.getItem(Constant.userId)+'&id='+id)
             .pipe(map(results => results));
  }

  productAdd(data)
  {
    return this.http.post(this.url+'productAdd?lid='+localStorage.getItem(Constant.lid)+"&user_id="+localStorage.getItem(Constant.userId),data)
             .pipe(map(results => results));
  }

  removeClient(id)
  {
    return this.http.get(this.url+'removeClient?lid='+localStorage.getItem(Constant.lid)+"&user_id="+localStorage.getItem(Constant.userId)+'&id='+id)
             .pipe(map(results => results));
  }

  clientAdd(data)
  {
    return this.http.post(this.url+'clientAdd?lid='+localStorage.getItem(Constant.lid)+"&user_id="+localStorage.getItem(Constant.userId),data)
             .pipe(map(results => results));
  }

  mark(id,type)
  {
    return this.http.get(this.url+'mark?lid='+localStorage.getItem(Constant.lid)+"&user_id="+localStorage.getItem(Constant.userId)+"&id="+id+'&type='+type)
             .pipe(map(results => results));
  }

  makeStripePayment(data)
  {
    return this.http.get(this.url+'makeStripePayment'+data+'&lid='+localStorage.getItem(Constant.lid))
             .pipe(map(results => results));
  }

  getApiUrl()
  {
    return this.url;
  }

  getReport(from,to)
  {
    return this.http.get(this.url+'getReport?user_id='+localStorage.getItem(Constant.userId)+'&from='+from+'&to='+to)
             .pipe(map(results => results));
  }

  incomeAdd(data)
  {
    return this.http.post(this.url+'incomeAdd?user_id='+localStorage.getItem(Constant.userId),data)
             .pipe(map(results => results));
  }

  getIncome(id,from,to)
  {
    return this.http.get(this.url+'getIncome?user_id='+localStorage.getItem(Constant.userId)+'&id='+id+'&from='+from+'&to='+to)
             .pipe(map(results => results));
  }

  expenseAdd(data)
  {
    return this.http.post(this.url+'expenseAdd?user_id='+localStorage.getItem(Constant.userId),data)
             .pipe(map(results => results));
  }

  getExpense(id,from,to)
  {
    return this.http.get(this.url+'getExpense?user_id='+localStorage.getItem(Constant.userId)+'&id='+id+'&from='+from+'&to='+to)
             .pipe(map(results => results));
  }

  taskAdd(data)
  {
    return this.http.post(this.url+'taskAdd?user_id='+localStorage.getItem(Constant.userId),data)
             .pipe(map(results => results));
  }

  getTask(id,view)
  {
    return this.http.get(this.url+'getTask?user_id='+localStorage.getItem(Constant.userId)+'&id='+id+'&view='+view)
             .pipe(map(results => results));
  }

  getProfit(id,from,to)
  {
    return this.http.get(this.url+'getProfit?user_id='+localStorage.getItem(Constant.userId)+'&id='+id+'&from='+from+'&to='+to)
             .pipe(map(results => results));
  }

  page()
  {
    return this.http.get(this.url+'page?lid='+localStorage.getItem(Constant.lid))
             .pipe(map(results => results));
  }

  getLang(id)
  {
    return this.http.get(this.url+'getLang?user_id='+localStorage.getItem(Constant.userId)+'&lang_id='+id)
             .pipe(map(results => results));
  }
}
