import { Component, ElementRef, Inject, ViewChild, AfterViewInit, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ModalController, ToastController } from '@ionic/angular';
import Cropper from 'cropperjs';

@Component({
  selector: 'app-image-cropper-modal',
  templateUrl: './image-cropper-modal.component.html',
  styleUrls: ['./image-cropper-modal.component.scss'],
})
export class ImageCropperModalComponent implements AfterViewInit {
  @ViewChild('cropperContainer', { static: false }) cropperContainer!: ElementRef;
  @ViewChild('imageElement', { static: false }) imageElement!: ElementRef<HTMLImageElement>;
  imageSrc: string;
  cropper: Cropper;
  public imageUrl: SafeUrl;
  loading: boolean = true; // Añade una propiedad para manejar el estado de carga
  @Input() aspectRatio: number = 4 / 3; // Valor predeterminado

  constructor(
    private modalController: ModalController,
    private sanitizer: DomSanitizer,
    private toastController: ToastController
  ) {}

  ngOnInit() {
    if (this.imageSrc) {
      this.setImageUrl(this.imageSrc);
    }
  }

  ngAfterViewInit(): void {

  }

  onImageLoad() {
    const imgElement = this.imageElement.nativeElement;
    this.initializeCropper(imgElement);
  }

  initializeCropper(imgElement: HTMLImageElement) {
    if (this.cropper) {
      this.cropper.destroy(); // Destruye cualquier instancia previa de Cropper
    }
    setTimeout(() => {
      const container = this.cropperContainer.nativeElement;
      const containerWidth = container.clientWidth;
      const containerHeight = container.clientHeight;

      if (containerHeight === 0 || containerWidth === 0) {
        console.error('error al calcular las dimensiones del contenedor donde se presentara la imagen a recortar');
        this.presentToast('Error en el proceso de recorte de imagen, por favor cargue otra imagen e intente nuevamente...');
        return
      }

      this.cropper = new Cropper(imgElement, {
        aspectRatio: this.aspectRatio,
        viewMode: 2,
        autoCropArea: 1,
        responsive: true,
        minContainerHeight: containerHeight,
        minContainerWidth: containerWidth,
        background: false,
        dragMode: 'move',
        zoomOnWheel: true,
        scalable: true,
        zoomable: true,
        guides: false,
      });

      this.loading = false; // Oculta el spinner y muestra la imagen y el Cropper
    }, 1000);
  }

  async cropImage() {
    const croppedCanvas = this.cropper.getCroppedCanvas();
    croppedCanvas.toBlob((blob) => {
      if (blob) {
        this.modalController.dismiss({ croppedBlob: blob });
      } else {
        console.error('Fallo el recorte de la imagen en el componente image-cropper-modal');
      }
    }, 'image/jpeg');
  }

  dismiss() {
    this.cropper.destroy();
    this.modalController.dismiss();
  }

  setImageUrl(url: string) {
    this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(url);
    console.log('Image URL set to:', this.imageUrl);
  }

  ngOnDestroy() {
    if (this.cropper) {
      this.cropper.destroy();
    }
  }

  async presentToast(txt, icono?) {
    const toast = await this.toastController.create({
      message: txt,
      duration: 3000,
      position: 'top',
      mode: 'ios',
      icon: icono? icono : undefined,
      color: 'primary'
    });
    toast.present();
  }
  
}