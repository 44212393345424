import {Component} from '@angular/core';

import {NavController, Platform, PopoverController} from '@ionic/angular';
import {StatusBar} from '@awesome-cordova-plugins/status-bar/ngx';
import {SplashScreen} from '@awesome-cordova-plugins/splash-screen/ngx';
import {OneSignal} from '@awesome-cordova-plugins/onesignal/ngx';
import {AuthService} from './service/auth.service';
import {Constant} from './shared/model/Constant';
import { PopoverContentComponent } from './popover-content/popover-content.component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  setting: any;
  text: any;
  user: any;
  dark_mode = false;
  dir = 'ltr';
  lang_data: any;
  showSubmenu = [];
  userId: string;
  isAuthorized: string;
  openProductMenu = false;
  openFormsMenu = false;
  openAdminMenu= false;
  openReportsMenu= false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private nav: NavController,
    private oneSignal: OneSignal,
    private authService: AuthService,
    private popoverController: PopoverController,
  ) {
    this.isAuthorized = localStorage.getItem(Constant.authorization);
    this.userId = localStorage.getItem(Constant.userId);
    this.getUser();
    if (localStorage.getItem(Constant.darkMode) == '1') {
      this.dark_mode = true;
    } else {
      this.dark_mode = false;
    }

    if (localStorage.getItem(Constant.darkMode) == '1') {
      document.body.setAttribute('color-theme', 'dark');
    } else {
      document.body.setAttribute('color-theme', 'light');
    }
    this.initializeApp();
    if (localStorage.getItem(Constant.setting) && localStorage.getItem(Constant.setting) != undefined) {
      this.setting = JSON.parse(localStorage.getItem(Constant.setting));
    }

    if (localStorage.getItem(Constant.appText) && localStorage.getItem(Constant.appText) != undefined) {
      this.text = JSON.parse(localStorage.getItem(Constant.appText));
    }

    // if (localStorage.getItem(Constant.userId) && localStorage.getItem(Constant.userId) != 'null') {
    //   //this.nav.navigateRoot('/main');.
    //   console.log('test')
    // } else {
    //   this.nav.navigateRoot('/login');
    // }

    if (localStorage.getItem(Constant.langData) && localStorage.getItem(Constant.langData) != "undefined") {
      this.lang_data = JSON.parse(localStorage.getItem(Constant.langData));

      this.dir = this.lang_data.type == '1' ? 'rtl' : 'ltr';
    }

  }

  toggleTheme(event) {
    if (event.detail.checked == true) {
      document.body.setAttribute('color-theme', 'dark');
      localStorage.setItem(Constant.darkMode, '1');

      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#000000');
      this.statusBar.styleDefault();
      this.statusBar.styleLightContent();


    } else {
      document.body.setAttribute('color-theme', 'light');
      localStorage.setItem(Constant.darkMode, '0');

      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#f4f5f8');
      this.statusBar.styleDefault();
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.getUserStyles();
      if (this.setting && this.setting.id) {
        this.sub();
      }

      this.statusBar.styleDefault();
      this.splashScreen.hide();

      if (localStorage.getItem(Constant.darkMode) == '1') {
        this.statusBar.overlaysWebView(false);
        this.statusBar.backgroundColorByHexString('#000000');
        this.statusBar.styleDefault();
        this.statusBar.styleLightContent();


      } else {
        this.statusBar.overlaysWebView(false);
        this.statusBar.backgroundColorByHexString('#f4f5f8');
        this.statusBar.styleDefault();
      }

    });
  }

  sub() {
    this.oneSignal.startInit(this.setting.push_app_id, this.setting.push_google);

    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);

    this.oneSignal.handleNotificationReceived().subscribe(() => {
      // do something when notification is received
    });

    this.oneSignal.handleNotificationOpened().subscribe(() => {
      // do something when a notification is opened
    });


    if (localStorage.getItem(Constant.userId) && localStorage.getItem(Constant.userId) != undefined) {
      this.oneSignal.sendTags({user_id: localStorage.getItem(Constant.userId)});
    }

    this.oneSignal.endInit();
  }

  logout() {
    localStorage.removeItem(Constant.userId);
    localStorage.removeItem(Constant.userData);

    this.nav.navigateRoot('/login');
  }


  getUser() {
    console.log('Find user by id', this.userId);
    if (localStorage.getItem(Constant.userData1) && localStorage.getItem(Constant.userData1) != undefined) {
      this.user = JSON.parse(localStorage.getItem(Constant.userData));
    }
    if (this.isAuthorized === 'false') {
      return;
    }
    this.authService.getUserById(this.userId).subscribe((user) => {
      localStorage.setItem(Constant.userData1, JSON.stringify(user));
      this.user = user;
    });
  }

  getUserStyles() {
    const defaultStyle = localStorage.getItem(Constant.defaultColor);
    if (defaultStyle) {
      this.setStyle(JSON.stringify(defaultStyle));
    }
    if (this.isAuthorized === 'false') {
      return;
    }
    this.authService.getStyles(this.userId).subscribe((styles) => {
      const style = styles[0];
      localStorage.setItem('default_color', JSON.stringify(style));
      this.setStyle(style);
    });
  }

  setStyle(style) {
    if (style.primary) {
      document.documentElement.style.setProperty('--ion-color-primary', style.primary);
      this.statusBar.backgroundColorByHexString(style.primary);
    }
    if (style.secondary) {
      document.documentElement.style.setProperty('--ion-color-secondary', style.secondary);
    }
  }

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverContentComponent,
      event: ev,
      translucent: true
    });
    return await popover.present();
  }
}
