import { Component } from '@angular/core';
import { NavController,Platform,LoadingController,IonSlides,ToastController,ModalController,NavParams } from '@ionic/angular';
import { ServerService } from '../service/server.service';
import { ActivatedRoute } from '@angular/router';
import {Constant} from "../shared/model/Constant";

@Component({
  selector: 'app-taskadd',
  templateUrl: 'taskadd.page.html',
  styleUrls: ['taskadd.page.scss'],
})
export class TaskaddPage {

text:any;
data:any;
id:any;
reminder:any;
title:any;
notes:any;
start_date:any;
end_date:any;


constructor(public navParams: NavParams,public loadingController: LoadingController,public server : ServerService,private route: ActivatedRoute,public nav : NavController,public toastController: ToastController,public modalController: ModalController) {

this.text    = JSON.parse(localStorage.getItem(Constant.appText));
this.data    = navParams.get('data');

if(this.data && this.data.id)
{
  this.notes      = this.data.text;
  this.id         = this.data.id;
  this.title      = this.data.title;
}

}

  ngOnInit()
  {

  }

  async saveData()
  {
    if(!this.title)
    {
      return this.presentToast(this.text.task_title);
    }

    await this.modalController.dismiss({notes:this.notes,title:this.title,id:this.id});
  }

  async close()
  {
    await this.modalController.dismiss({});
  }

  async presentToast(txt) {
    const toast = await this.toastController.create({
      message: txt,
      duration: 3000,
      position : 'top',
      mode:'ios',
      color:'dark'
    });
    toast.present();
  }
}
