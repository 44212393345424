import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {AlertController, LoadingController, ModalController, NavController, ToastController} from '@ionic/angular';
import {ServerService} from '../../service/server.service';
import {ActivatedRoute} from '@angular/router';
import {Constant} from "../../shared/model/Constant";
import {FormBuilder, FormGroup, NgForm, Validators} from "@angular/forms";
import {AdminService} from "../../service/admin.service";
import {ProductService} from "../../service/product.service";
import {AuthService} from "../../service/auth.service";


@Component({
  selector: 'app-product-add',
  templateUrl: 'product-add.page.html',
  styleUrls: ['product-add.page.scss'],
})
export class ProductAddPage implements OnInit {
  @ViewChild('input', {static: false}) searchInput: { setFocus: () => void };
  @ViewChild('form', {static: false}) ngForm: NgForm;


  name: any;
  price: any;
  data: any = [];
  type: any = 0;
  title: any;
  url: any;
  logo: any;
  product: any;
  text: any;
  modules: any;
  form: FormGroup;
  loading: HTMLIonLoadingElement;
  states: any;
  categories: any;
  commissionCategories: any;
  select = false;
  commissionCategoryId: number;
  typeProducts: any;
  productTypeId: number;
  selectedProductTypeId: number | null = null;
  providers: any;
  taxes: any[] = [{}];
  productTaxes: any;
  hasTaxes: boolean;
  selectedCategory: any;
  userId: any;

  constructor(public alertController: AlertController,
              private route: ActivatedRoute,
              public server: ServerService,
              public toastController: ToastController,
              private nav: NavController,
              public loadingController: LoadingController,
              private adminService: AdminService,
              private formBuilder: FormBuilder,
              public modalController: ModalController,
              private navCtrl: NavController,
              private productService: ProductService,
              private authService: AuthService,
              private change: ChangeDetectorRef,
  ) {

    this.type = this.route.snapshot.paramMap.get('type');

    if (!localStorage.getItem(Constant.userId) || localStorage.getItem(Constant.userId) == undefined || localStorage.getItem(Constant.userId) == 'null') {
      this.nav.navigateRoot('/home');
    }

  }

  async ngOnInit() {
    this.loading = await this.loadingController.create({
      message: '',
      spinner: 'bubbles',
      duration: 30000
    });
    this.userId = JSON.parse(localStorage.getItem(Constant.userId));
    this.initForm();
    this.getProductCategoryId();
    this.getCommissionCategory();
    this.getProductsTypes();
    this.getProviders();
    this.getTypeTaxes();
    this.getStatusGlobalId();
  }

  initForm() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      stateId: ['', Validators.required]
    });
  }

  ionViewDidEnter() {
    if (localStorage.getItem(Constant.products) && localStorage.getItem(Constant.products) != undefined && localStorage.getItem(Constant.products) != 'null') {
      this.data = JSON.parse(localStorage.getItem(Constant.products));
    }
  }

  async addProduct(data) {
    data.taxesProduct = this.taxes;
    data.registeredBy = this.userId;
    await this.loading.present();
    this.productService.createProducts(data).subscribe(response => {
      this.loading.dismiss();
      this.presentToast('Producto creado con éxito');
      this.ngForm.reset();
      this.close(true);
    }, error => {
      console.warn('Error al crear producto', error);
      this.loading.dismiss();
      this.presentToast('Error al crear producto');
    });
  }

  getStatusGlobalId() {
    this.loading.present();
    this.productService.getGlobalStatus().subscribe((res: any) => {
      this.states = res;
      this.loading.dismiss();
    })
  }

  getProductCategoryId() {
    this.loading.present();
    this.productService.getProductCategoryId().subscribe((res: any) => {
      this.categories = res;
      this.loading.dismiss();
    });
  }

  getCommissionCategory() {
    this.loading.present();
    this.productService.getCommissionCategory().subscribe((res: any) => {
      this.commissionCategories = res;
      this.loading.dismiss();
    });
  }

  getProductsTypes() {
    this.loading.present();
    this.productService.getProductsTypes().subscribe((res: any) => {
      this.typeProducts = res;
      this.loading.dismiss();
    });
  }

  getProviders() {
    this.loading.present();
    this.authService.getProviders().subscribe((res: any) => {
      this.providers = res;
      this.loading.dismiss();
    });
  }

  getTypeTaxes() {
    this.loading.present();
    this.productService.getTypeTaxes().subscribe((res: any) => {
      this.productTaxes = res;
      this.loading.dismiss();
    });
  }


  async presentToast(txt) {
    const toast = await this.toastController.create({
      message: txt,
      duration: 3000,
      position: 'top',
      mode: 'ios',
      color: 'dark'
    });
    toast.present();
  }

  async close(data?: any) {
    await this.modalController.dismiss(data);
  }

  async addTaxes() {
    this.taxes.push({});
    this.change.detectChanges();
  }

  buildCompleteName(item: any): string {
    const names = ['firstName', 'secondName', 'firstSurname', 'secondSurname'];
    return names
      .map(value => item[value])
      .filter(value => value !== null && value !== undefined)
      .join(' ');
  }

  setHasTaxes(checked: boolean) {
    this.hasTaxes = checked;
    this.change.detectChanges();
  }

  selectCheckboxCategory(checkboxIndex: number) {
    this.commissionCategoryId = checkboxIndex;
    this.select = true;
    const checkboxCount = 2;
    for (let i = 0; i < checkboxCount; i++) {
      if (i !== checkboxIndex) {
        const checkboxElement = document.getElementsByClassName('checkoxCategory')[i] as HTMLIonCheckboxElement;
        checkboxElement.checked = false;
      }
    }
  }

  selectCheckboxType(checkboxIndex: number) {
    this.productTypeId = checkboxIndex;
    this.select = true;
    const checkboxCount = 2;
    for (let i = 0; i < checkboxCount; i++) {
      if (i !== checkboxIndex) {
        const checkboxElement = document.getElementsByClassName('checkboxType')[i] as HTMLIonCheckboxElement;
        checkboxElement.checked = false;
      }
    }
  }
}
